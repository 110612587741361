import React, {useEffect} from "react"
import { graphql } from "gatsby"
import { navigate } from '@reach/router';

export default ({ data }) => {
  console.log(data)
  useEffect(() => {
    navigate(`https://sizify.com${data.file.publicURL}`)
    return null
  }, [])
  return (
    <div></div>
      // <iframe src={`https://docs.google.com/viewerng/viewer?embedded=true&url=https://dtcgo.co${data.file.publicURL}&embedded=true`}
      // style={{position: 'absolute',width:'100%',height:'100%'}} frameborder="0"></iframe>
    // <object data={data.file.publicURL} type="application/pdf">
    //   <embed src={data.file.publicURL}  type="application/pdf" alt="pdf" />
    // </object>

  )
}

export const query = graphql`
  query($slug: String!) {
    file(relativePath: { eq: $slug }) {
      absolutePath
      relativePath
      base
      publicURL
      
    }
  }
`
